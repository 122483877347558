frappe.provide("parsimony");
const paywall_page = "/account_suspended";

function redirect_to_paywall() {
	if (window.location.pathname !== paywall_page) window.location = paywall_page;
}

frappe.ready(function () {
	if (typeof parsimony.site_enabled === "undefined") {
		frappe
			.call({
				method: "parsimony.is_site_enabled",
			})
			.done((r) => {
				if (r.message === false || r.message === 0) {
					parsimony.site_enabled = false;
					redirect_to_paywall();
				} else {
					parsimony.site_enabled = true;
				}
			});
	} else if (parsimony.site_enabled === false) {
		redirect_to_paywall();
	}
});
